<template>
  <div>
    <NuxtLoadingIndicator color="#e2241b" />
    <div class="flex flex-col justify-between h-screen font-ff-condensed">
      <Offline />
      <Navigation v-if="!isMobile" />
      <MobileNavigation v-else />

      <main
        id="app"
        class="relative w-full mx-auto mb-auto"
        style="max-width: 2560px"
      >
        <slot />
      </main>

      <Newsletter v-if="!isStorePage" />

      <Footer v-if="!isStorePage" />
      <client-only>
        <ModalsContainer />
      </client-only>
      <CookieBanner />
    </div>
  </div>
</template>

<script setup>
import { ModalsContainer } from 'vue-final-modal'
import { useMainStore } from '@/store/index'
import { useLocaleStore } from '@/store/locales'
import { useAppStore } from '~/store/app'

// use methods
const route = useRoute()
const router = useRouter()
const mainStore = useMainStore()
const localeStore = useLocaleStore()
const nuxtApp = useNuxtApp()
const appStore = useAppStore()

//data
const $i18nPath = nuxtApp.$i18nPath
const isMobile = ref(false)
const windowWidth = ref(0)

onServerPrefetch(async () => {
  // ✅ this will work
  await mainStore.fetchInitial()
})

watch(
  route,
  (newValue, oldValue) => {
    // this.$store.dispatch('menu/hideAll')
    if (route.query && route.query.login) {
      // router.replace({ query: null })
      // const { open, close } = useModal({
      //   component: SignInModal,
      //   attrs: {
      //     classes: 'right-side-modal',
      //   },
      // })
      // open()
    }
  },
  { deep: true }
)

// computed
const isStorePage = computed(() => {
  return route.path.startsWith($i18nPath('/stores'))
})

const isAboutUsPage = computed(() => {
  return route.path.startsWith($i18nPath('/about-us'))
})

const isHomePage = computed(() => {
  return route.name == 'country'
})

const countryLocale = useCookie('CountryLocale', {
  path: '/',
  watch: 'shallow',
})
countryLocale.value = `${mainStore.country}/${mainStore.locale}`

onBeforeMount(() => {
  if (import.meta.browser && nuxtApp.$pusher) {
    const channel = nuxtApp.$pusher.subscribe('deployments')
    channel.bind('new-deploy', (data) => appStore.setForceReload(true))
  }
})

onMounted(async () => {
  windowWidth.value = window.innerWidth
  if (windowWidth.value <= 1023) {
    isMobile.value = true
  } else {
    isMobile.value = false
  }

  await nextTick()
  window.addEventListener('resize', onResize)
  // nuxtApp.$loadGTM()

  if (nuxtApp.$loadIntercom) {
    nuxtApp.$loadIntercom()
  }
})

onBeforeUnmount(() => {
  window.onscroll = null
  window.removeEventListener('resize', onResize)
})

const onResize = () => {
  windowWidth.value = window.innerWidth
  if (windowWidth.value <= 1023) {
    isMobile.value = true
  } else {
    isMobile.value = false
  }
}
/***  head setup ***/
const baseAppUrl = nuxtApp.$config.public.APP_URL
const styles = []

// eslint-disable-next-line
let metaLinks = [
  { rel: 'alternate', href: `${baseAppUrl}`, hreflang: 'x-default' },
  {
    rel: 'canonical',
    href: `${baseAppUrl}${route.path.substring(1)}`,
  },
]

// Add alternate urls
mainStore.sites
  .filter((site) => site.slug === mainStore.country)
  .forEach((site) => {
    site.urls.forEach((item) => {
      metaLinks.push({
        rel: 'alternate',
        href: item.url,
        hreflang: item.locale_code,
      })
    })
  })

useHead({
  titleTemplate: '%s | Goccia Bike',
  link: metaLinks,
  style: styles,
  script: [
    {
      src: 'https://kit.fontawesome.com/91a0be131e.js',
      crossorigin: 'anonymous',
      defer: true,
    },
  ],
  htmlAttrs: {
    lang: mainStore.locale,
    dir: mainStore.isRtl ? 'rtl' : 'ltr',
  },
})
</script>
